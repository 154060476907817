<template>
    <div class="">
        <div class="header">

            <div class="breadcrumbs">
                <router-link to="/exames" tag="span" class="link">
                    Exames/procedimentos
                </router-link>
                <ChevronRight class="chevron" />
                <span> Visualizar </span>
            </div>
            <div class="actions">
                <b-button
                    class="wh-button mr-2"
                    variant="outline-primary"
                    @click="goToReportRequest"
                >
                    Solicitar laudo
                </b-button>
                <b-button
                    class="wh-button"
                    variant="primary"
                    @click="$router.go(-1)"
                >
                    Voltar
                </b-button>
            </div>
        </div>

        <iframe :src="url" frameborder="0" v-if="url"></iframe>

    </div>
</template>
<script>
import api from '../api'
export default {
    components: {
        ChevronRight: () => import('@/assets/icons/chevron-right.svg')
    },
    data() {
        return {
            labpacsItemId: this.$route.params.labpacsItemId,
            labpacsItem: null,
            url: null
        }
    },
    mounted() {
        this.getLabpacsItem()
    },
    methods: {
        async getLabpacsItem() {
            const isLoading = this.$loading.show()
            try {
                const response = await api.getLabpabcsItem(this.labpacsItemId)
                if (response.data && response.status === 200) {
                    this.labpacsItem = response.data
                    await this.getUrlDicon()
                }
            } catch (error) {
                console.error(error)
                this.$toast.error('Erro ao obter o exame')
                throw error
            } finally {
                isLoading.hide()
            }
        },
        async getUrlDicon() {
            try {
                const response = await api.getUrlDicon(this.labpacsItem.id)
                if (response.data && response.status === 200) {
                    // preg_replace(['/\\\/', '/\\r/', '/\\n/'], '', $res)
                    this.url = response.data.url.replace(/\\/g, '')
                }
            } catch (error) {
                console.error(error)
                this.$toast.error('Erro ao obter o exame')
                throw error
            }
        },
        goToReportRequest() {
            window.location.replace('/laudos-medicos/laudos-pendentes')
        }
    }
    
}
</script>
<style lang="scss" scoped>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .breadcrumbs {
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      padding: 15px;
    
      .chevron {
        width: 24px;
        stroke: var(--neutral-600);
        margin: 0 8px;
      }
      
      .link {
        color: var(--blue-500);
        cursor: pointer;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 15px;

        .wh-button {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 0 10px;
        }
    }
}

iframe {
    width: 100%;
    height: 90vh;
    border: none;
    padding: 10px 15px;
}
</style>